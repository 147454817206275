import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { SassyText, Text } from "../core/commonExports"
import { Button } from "../core/Button"
import checkOfficeHours from "../../utils/checkOfficeHours"
import { useMixpanel } from "../../context/MixpanelContext"
import { getPageName } from "../../utils/getCurrentPageName"
import { checkIsAfterMidnight, getBlogLink, getPolicyName } from "./thankyouScreenUtils"

import YTIcon from "../../assets/images/campaigns/icons/youtube-icon.svg"
import BlogIcon from "../../assets/images/campaigns/icons/blog-icon.svg"

const ThankYouScreen = ({ sumInsured, isSafetyPlan }) => {
  const mixpanel = useMixpanel()
  const isOfficeHours = checkOfficeHours()
  const isAfterMidnight = checkIsAfterMidnight()

  let advisorCallTime = "will call you tomorrow between 10 AM & 7PM"
  if (isOfficeHours) {
    advisorCallTime = "will reach out in the next 10 minutes"
  } else if (isAfterMidnight) {
    advisorCallTime = "will call you today between 10 AM & 7PM"
  }

  const policyName = getPolicyName(Number(sumInsured))
  const pageName = getPageName()

  const trackCTAClick = (ctaType) => {
    let mxEventName = ""
    if (ctaType === "ytVideo") {
      mxEventName = `Clicked "Watch to know more" (${pageName})`
    } else if (ctaType === "blog") {
      mxEventName = `Clicked "Read Blogs" (${pageName})`
    }

    if (mxEventName) {
      mixpanel.track(mxEventName)
    }
  }

  return (
    <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
      <ImgContainer>
        <StaticImage 
          src="../../assets/images/campaigns/leadFormAgentSupport.webp"
          alt="thank you"
          placeholder="blurred"
          loading="lazy"
          width={396}
          height={266}
        />
      </ImgContainer>
      <Text fontSize="40px" mfontSize="24px" lineHeight="60px" mlineHeight="30px" style={{fontFamily: "Dream Orphans", margin: "0", textAlign: "center"}}>
        <SassyText>Thank You!</SassyText>
      </Text>
      <Text fontSize="20px" mfontSize="14px" lineHeight="25px" mlineHeight="17px" fontWeight="bold" color={isSafetyPlan ? "#333" : "#8668AD"} style={{textAlign: "center"}}>
        {isSafetyPlan ? 
          "Our advisors will reach out in the next 10 minutes to discuss the best plan for your business"
          : `Our insurance advisor ${advisorCallTime} to explain the policy in detail & find the best plan for you.`
        }
      </Text>
      {!isSafetyPlan ? (
        <CTAContainer>
          {policyName !== "Liability" && (
            <a href="https://www.youtube.com/watch?v=TXAJHwKV_0I" target="_blank">
              <Button 
                onClick={e => trackCTAClick("ytVideo")}
                btnIcon={<YTIcon style={{marginRight: "0.25rem"}} />}
                label="Watch to know more"
                uppercaseLabel={false}
                style={{background: "#F24E1E", padding: "12px", borderRadius: "14px"}}
                mobileStyles={{height: "40px"}}
              />
            </a>
          )}
          <a href={getBlogLink(policyName)} target="_blank">
            <Button 
              onClick={e => trackCTAClick("blog")}
              btnIcon={<BlogIcon style={{marginRight: "0.25rem"}} />}
              type="secondary"
              label="Read Blogs"
              uppercaseLabel={false}
              style={{padding: "12px", borderRadius: "14px"}}
              mobileStyles={{height: "40px"}}
            />
          </a>
        </CTAContainer>
      ) : null}
    </div>
  )
}

const CTAContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 12px;
  @media (max-width: 768px ) {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }
`

const ImgContainer = styled.div`
  width: min(396px, 100%);
  margin-bottom: 1rem;
  @media screen and (max-width: 768px) {
      width: min(250px, 100%);
      margin: auto;
      margin-bottom: 0.5rem;
  }
`

export default ThankYouScreen